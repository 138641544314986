.page-index {
  width: 100%;
  padding-top: 0;
  overflow: hidden;
  background: url("../../imgs/bg.png") 50% no-repeat;
  background-size: cover;
}

.page-index .slogan {
  width: 100%;
  height: 1.7rem;
  background: url("../../imgs/slogan.png") no-repeat 50%/cover;
}

.page-index .select-age {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
  margin: 0.3rem;
  color: #ada4a5;
  font-weight: bold;
  font-size: 0.28rem;
  text-align: center;
  background: url("../../imgs/pickview-bg.png") no-repeat 50%/contain,url("../../imgs/icon-arrow.png") no-repeat 95% 50%/0.32rem;
  border-radius: 0.16rem;
}

.page-index .select-age .select-el {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  color: #fff;
  font-weight: bold;
  text-align: center;
  text-align-last: center;
  background: none;
  border: 0;
  outline: none;
}

.page-index .select-age .select-el option {
  display: block;
  font-weight: bold;
  text-align: center;
}

.page-index .gallery {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-index .gallery .arrow {
  position: absolute;
  top: 50%;
  width: 0.88rem;
  height: 1.14rem;
  margin-top: -0.57rem;
}

.page-index .gallery .arrow:active {
  opacity: 0.75;
}

.page-index .gallery .arrow.left {
  left: 0.2rem;
  background: url("../../imgs/arrowhead-left.png") no-repeat 50%/cover;
}

.page-index .gallery .arrow.right {
  right: 0.2rem;
  background: url("../../imgs/arrowhead-right.png") no-repeat 50%/cover;
}

.page-index .gallery .gallery-wrapper {
  width: 5.44rem;
  height: 7.05rem;
  padding: 0.28rem;
  background: url("../../imgs/photo-bg.png") no-repeat 50%/cover;
}

.page-index .gallery .gallery-wrapper .gallery-list {
  width: 4.9rem;
  height: 6.5rem;
}

.page-index .gallery .gallery-wrapper .gallery-list .gallery-item {
  width: 4.9rem;
  height: 6.5rem;
}

.page-index .gallery .gallery-wrapper .gallery-list .gallery-item.g0 {
  background: url("../../imgs/0.jpg") no-repeat 50%/cover;
}

.page-index .gallery .gallery-wrapper .gallery-list .gallery-item.g1 {
  background: url("../../imgs/1.jpg") no-repeat 50%/cover;
}

.page-index .gallery .gallery-wrapper .gallery-list .gallery-item.g2 {
  background: url("../../imgs/2.jpg") no-repeat 50%/cover;
}

.page-index .gallery .gallery-wrapper .gallery-list .gallery-item.g3 {
  background: url("../../imgs/3.jpg") no-repeat 50%/cover;
}

.page-index .gallery .gallery-wrapper .gallery-list .gallery-item.g4 {
  background: url("../../imgs/4.jpg") no-repeat 50%/cover;
}

.page-index .gallery .gallery-wrapper .gallery-list .gallery-item.g5 {
  background: url("../../imgs/5.jpg") no-repeat 50%/cover;
}

.page-index .gallery .gallery-wrapper .gallery-list .gallery-item.g6 {
  background: url("../../imgs/6.jpg") no-repeat 50%/cover;
}

.page-index .gallery .gallery-wrapper .gallery-list .gallery-item.g7 {
  background: url("../../imgs/7.jpg") no-repeat 50%/cover;
}

.page-index .gallery .gallery-wrapper .gallery-list .gallery-item .age {
  position: absolute;
  top: 0.2rem;
  left: 0;
  z-index: 99;
  width: 1.1rem;
  height: 0.66rem;
  color: #fff;
  font-weight: bold;
  font-size: 0.36rem;
  line-height: 0.66rem;
  text-align: center;
  background: url("../../imgs/age-bg.png") no-repeat 50%/1.1rem 0.66rem;
}

.page-index .desc {
  color: #fff;
  text-align: center;
}

.page-index .tip {
  padding: 0.2rem 0;
  color: #e3624b;
  font-size: 0.26rem;
  text-align: center;
}

.page-index .btns {
  position: relative;
  width: 100%;
  padding: 0.15rem 0.2rem;
}

.page-index .btns .btn {
  width: 100%;
  height: 0.8rem;
  background: url("../../imgs/btn-upload.png") no-repeat 50%/cover;
}

.page-index .btns .file {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  opacity: 0;
}