.page-pay {
  width: 100%;
  padding-bottom: 0.4rem;
  overflow: hidden;
  background: url("../../imgs/bg.png") 50% no-repeat #000;
  background-size: cover;
}

.page-pay .slogan {
  width: 100%;
  height: 1.6rem;
  background: url("../../imgs/slogan.png") no-repeat 50%/cover;
}

.page-pay .photo-ad .photo-pass-wrapper {
  position: relative;
  width: 5.44rem;
  height: 7.05rem;
  margin: 0 auto 0 auto;
  padding: 0.28rem;
  background: url("../../imgs/photo-bg.png") no-repeat 50%/cover;
}

.page-pay .photo-ad .photo-pass-wrapper .item {
  position: absolute;
  top: 0.27rem;
  left: 0.27rem;
  width: 4.9rem;
  height: 6.5rem;
  opacity: 0;
  transition: opacity 1s linear;
}

.page-pay .photo-ad .photo-pass-wrapper .item.local {
  opacity: 1;
}

.page-pay .photo-ad .photo-pass-wrapper .item.local.animation {
  opacity: 0;
}

.page-pay .photo-ad .photo-pass-wrapper .item.passthrough {
  opacity: 0;
}

.page-pay .photo-ad .photo-pass-wrapper .item.passthrough.animation {
  opacity: 1;
}

.page-pay .photo-ad .photo-pass-wrapper .age {
  position: absolute;
  top: 0.4rem;
  left: 0.2rem;
  z-index: 99;
  width: 1.1rem;
  height: 0.66rem;
  color: #fff;
  font-weight: bold;
  font-size: 0.36rem;
  line-height: 0.66rem;
  text-align: center;
  background: url("../../imgs/age-bg.png") no-repeat 50%/1.1rem 0.66rem;
}

.page-pay .photo-ad .passthrough-handle {
  margin-top: 0.8rem;
}

.page-pay .photo-ad .passthrough-handle .btns {
  display: flex;
  width: 100%;
  padding: 0.15rem 0.2rem;
}

.page-pay .photo-ad .passthrough-handle .btns .btn {
  flex: 1;
  margin: 0 0.2rem;
}

.page-pay .photo-ad .passthrough-handle .btns .pass {
  width: 100%;
  background: url("../../imgs/btn-ad.png") no-repeat 50%/cover;
}

.page-pay .photo-ad .passthrough-handle .btns .again {
  background: url("../../imgs/btn-again.png") no-repeat 50%/cover;
}

.page-pay .photo-ad .passthrough-handle .btns .download {
  background: url("../../imgs/btn-download.png") no-repeat 50%/cover;
}

.page-pay .photo-ad .price {
  color: #fff;
  font-weight: bold;
  font-size: 0.28rem;
  text-align: center;
}

.page-pay .photo-ad .price em {
  color: #e93323;
  font-size: 0.5rem;
  font-style: normal;
}

.page-pay .photo-ad .countdown {
  padding: 0.1rem 0;
  color: #fff;
  font-size: 0.28rem;
  text-align: center;
}

.page-pay .photo-ad .pay-button {
  padding: 0 0.4rem;
}

.page-pay .photo-ad .pay-button .btn {
  margin: 0.2rem 0;
  font-weight: bold;
  font-size: 0.4rem;
}

.page-pay .photo-ad .pay-button .btn .icon {
  width: 0.6rem;
  height: 0.6rem;
  margin: 0 0.1rem;
}

.page-pay .photo-ad .pay-button .btn.wechat {
  background-color: #07c160;
}

.page-pay .photo-ad .pay-button .btn.wechat .icon {
  background: url("../../imgs/icon-wechat.png") no-repeat 50%/cover;
}

.page-pay .photo-ad .pay-button .btn.alipay {
  background-color: #1296db;
}

.page-pay .photo-ad .pay-button .btn.alipay .icon {
  background: url("../../imgs/icon-alipay.png") no-repeat 50%/cover;
}

.page-pay .photo-ad .tip {
  margin: 0.1rem 0.2rem;
  padding: 0.2rem;
  color: #333;
  font-size: 0.28rem;
  background-color: #fff;
  border-radius: 0.1rem;
}

.page-pay .photo-ad .tip .count {
  font-size: 0.32rem;
  text-align: center;
  text-indent: 0;
}

.page-pay .photo-ad .tip .count em {
  color: #e56767;
  font-weight: bold;
  font-style: normal;
}

.page-pay .photo-ad .tip p {
  text-indent: 2em;
}

.pop-red-pack .x-modal__inner {
  background: url("./imgs/icon-hongbao.png") no-repeat 50%/cover;
}

.pop-red-pack .x-modal__inner .x-dialog__inner {
  position: relative;
}

.pop-red-pack .x-modal__inner .x-dialog__inner .inner {
  padding-top: 1rem;
}

.pop-red-pack .x-modal__inner .x-dialog__inner .inner h1 {
  color: #e38000;
  font-size: 0.5rem;
  text-align: center;
}

.pop-red-pack .x-modal__inner .x-dialog__inner .inner h2 {
  color: #f00;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}

.pop-red-pack .x-modal__inner .x-dialog__inner .inner h2 sup {
  color: #f00;
  font-weight: bold;
  font-size: 0.4rem;
}

.pop-red-pack .x-modal__inner .x-dialog__inner .inner h6 {
  font-size: 0.3rem;
  text-align: center;
}

.pop-red-pack .x-modal__inner .x-dialog__inner .inner .countdown {
  margin-top: 2.2rem;
  color: #fff;
  font-size: 0.4rem;
  text-align: center;
}

.pop-red-pack .x-modal__inner .x-dialog__inner .inner .btns {
  margin: 0.1rem 0;
}

.pop-red-pack .x-modal__inner .x-dialog__inner .inner .btns .btn {
  margin: 0.3rem 0;
}

.pop-red-pack .x-modal__inner .x-dialog__inner .inner .btns .btn.ok {
  font-size: 0.4rem;
  background-color: #f99e08;
  animation: sharke 1s linear infinite;
}

.pop-red-pack .x-modal__inner .x-dialog__inner .inner .btns .btn.cancel {
  color: #f7b352;
  font-size: 0.4rem;
  border: solid 0.02rem #f7b352;
}

@keyframes sharke {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.05);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}